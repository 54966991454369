import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import layout from '@/layout'
import UserManageRouter from './modules/UserManage'
import RoleListRouter from './modules/RoleList'
import PermissionListRouter from './modules/PermissionList'
import OrderManageRouter from './modules/OrderManage'
import GoodsManageeRouter from './modules/GoodsManage'
import MessageManageRouter from './modules/MessageManage'
import StoreManageRouter from './modules/StoreManage'
import SysManageRouter from './modules/SysManage'
import store from '@/store'

/**
 * 私有路由表
 */
export const privateRoutes = [
  UserManageRouter,
  RoleListRouter,
  PermissionListRouter,
  OrderManageRouter,
  GoodsManageeRouter,
  StoreManageRouter,
  MessageManageRouter,
  SysManageRouter
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/profile',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/index'),
        meta: {
          title: '个人中心',
          icon: 'personnel'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.permission
    menus.forEach(menu => {
      menu.name && router.removeRoute(menu.name)
    })
  }
}

const router = createRouter({
  /* history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(), */
  history: createWebHashHistory(),
  routes: publicRoutes
})

export default router
