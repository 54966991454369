export default {
  namespaced: true,
  state: () => ({
    orderStatus: {
      0: {
        label: '待接单',
        value: 0,
        type: 'warning'
      },
      1: {
        label: '已支付，待核销',
        value: 1,
        type: ''
      },
      2: {
        label: '已核销',
        value: 2,
        type: 'success'
      },
      3: {
        label: '取消审核中',
        value: 3,
        type: 'warning'
      },
      4: {
        label: '取消审核通过',
        value: 4,
        type: 'success'
      },
      5: {
        label: '取消审核不通过',
        value: 5,
        type: 'danger'
      },
      6: {
        label: '已下单，待支付',
        value: 6,
        type: 'warning'
      },
      7: {
        label: '已退款',
        value: 7,
        type: ''
      },
      8: {
        label: '提成',
        value: 8,
        type: 'success'
      },
      9: {
        label: '提现',
        value: 9,
        type: 'success'
      }
    },
    // 子订单状态
    orderState: {
      10: {
        label: '单个商品发起退款',
        value: 10,
        type: 'warning'
      },
      11: {
        label: '单个商品退款到账',
        value: 11,
        type: 'success'
      }
    },
    orderMethod: {
      1: {
        label: '送货上门',
        value: 1,
        type: 'warning'
      },
      2: {
        label: '门店自提',
        value: 2,
        type: ''
      }
    },
    payType: {
      1: {
        label: '在线支付',
        value: 1,
        type: 'warning'
      }
    }
  }),
  mutations: {
    setOrderStatus(state, value) {
      state.orderStatus = value
    },
    setOrderMethod(state, value) {
      state.orderMethod = value
    }
  },
  actions: {}
}
