import layout from '@/layout'

export default {
  path: '/order',
  component: layout,
  redirect: '/order/manage',
  name: 'orderManage',
  meta: {
    title: '订单管理',
    icon: 'personnel'
  },
  children: [
    {
      path: '/order/manage',
      component: () =>
        import(/* webpackChunkName: "user-manage" */ '@/views/order/index'),
      meta: {
        title: '订单列表',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/order/info/:id',
      name: 'orderInfo',
      component: () =>
        import(/* webpackChunkName: "user-manage" */ '@/views/order/info'),
      props: true,
      meta: {
        title: '订单信息'
      }
    }
  ]
}
