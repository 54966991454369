const steps = () => {
  return [
    {
      element: '#guide-start',
      popover: {
        title: '引导',
        description: '打开引导功能',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-hamburger',
      popover: {
        title: '汉堡按钮',
        description: '打开和关闭左侧菜单'
      }
    },
    {
      element: '#guide-breadcrumb',
      popover: {
        title: '面包屑',
        description: '指示当前页面位置'
      }
    },
    {
      element: '#guide-search',
      popover: {
        title: '搜索',
        description: '页面链接搜索',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-full',
      popover: {
        title: '全屏',
        description: '页面显示切换',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-theme',
      popover: {
        title: '主题',
        description: '更换项目主题',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-lang',
      popover: {
        title: '国际化',
        description: '语言切换',
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-tags',
      popover: {
        title: '标签',
        description: '已打开页面标签'
      }
    },
    {
      element: '#guide-sidebar',
      popover: {
        title: '菜单',
        description: '项目功能菜单',
        position: 'right-center'
      }
    }
  ]
}
export default steps
