import layout from '@/layout'

export default {
  path: '/user',
  component: layout,
  redirect: '/user/manage',
  name: 'userManage',
  meta: {
    title: '用户管理',
    icon: 'personnel'
  },
  children: [
    {
      path: '/user/manage',
      component: () =>
        import(
          /* webpackChunkName: "user-manage" */ '@/views/user-manage/index'
        ),
      meta: {
        title: '用户列表',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/user/info/:id',
      name: 'userInfo',
      component: () =>
        import(/* webpackChunkName: "user-manage" */ '@/views/user-info/index'),
      props: true,
      meta: {
        title: '用户信息'
      }
    },
    {
      path: '/user/import',
      name: 'import',
      component: () =>
        import(/* webpackChunkName: "user-manage" */ '@/views/import/index'),
      meta: {
        title: 'excelImport'
      }
    },
    {
      path: '/wechatuser/manage',
      component: () => import('@/views/wechat-user-manage/index'),
      meta: {
        title: '微信用户管理',
        icon: 'personnel'
      }
    },
    {
      path: '/wechatuser/info/:id',
      name: 'wxUserInfo',
      component: () => import('@/views/wechat-user-manage/info'),
      props: true,
      meta: {
        title: '微信用户信息'
      }
    }
  ]
}
