import { login, getUserInfo, getUserInfoMenu } from '@/api/sys'
// import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import router, { resetRouter } from '@/router'
import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
    role: {
      0: {
        label: '普通用户',
        value: 0
      },
      1: {
        label: '店员',
        value: 1
      },
      2: {
        label: '店主',
        value: 2
      }
    }
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    login(context, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({
          username,
          // password: md5(password)
          password: password
        })
          .then(data => {
            this.commit('user/setToken', data.token)
            // 保存登录时间
            setTimeStamp()
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async getUserInfo(context) {
      const res = await getUserInfo()
      const menus = await getUserInfoMenu()
      // TODO 暂时手工添加白名单路由页面
      /* const whiteArr = [
        'userManage',
        'roleList',
        'permissionList',
        'articleRanking',
        'articleCreate'
      ] */
      const tmpObj = {
        ...res,
        title: res.roleList.length > 0 ? res.roleList[0].name : '',
        permission: {
          // menus: whiteArr,
          // menuType 0 菜单1权限
          menus: menus.filter(item => item.menuType === 0),
          points: menus.filter(item => item.menuType === 1)
        },
        avatar: res.userFace
      }
      console.log('tmpObj :>> ', tmpObj)
      this.commit('user/setUserInfo', tmpObj)
      return tmpObj
    },
    logout() {
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      this.commit('app/initTagsViewList')
      removeAllItem()
      router.push('/login')
    }
  }
}
