import layout from '@/layout'

export default {
  path: '/goods',
  component: layout,
  redirect: '/goods/manage',
  name: 'goodsManage',
  meta: {
    title: '商品管理',
    icon: 'personnel'
  },
  children: [
    {
      path: '/goods/manage',
      component: () => import('@/views/goods-manager/goods/index'),
      name: 'goodsList',
      meta: {
        title: '商品列表',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/goods/unit',
      component: () => import('@/views/goods-manager/unit/index.vue'),
      name: 'goodsUnit',
      meta: {
        title: '商品单位',
        icon: 'personnel-manage'
      }
    }
  ]
}
