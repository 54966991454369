import layout from '@/layout'

export default {
  path: '/message',
  component: layout,
  redirect: '/message/manage',
  name: 'messageManage',
  meta: {
    title: '留言管理',
    icon: 'personnel'
  },
  children: [
    {
      path: '/message/manage',
      component: () => import('@/views/message/message/index.vue'),
      meta: {
        title: '留言列表',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/message/reply/:id/:data',
      name: 'messageReply',
      component: () => import('@/views/message/reply/index.vue'),
      props: true,
      meta: {
        title: '回复'
      }
    }
  ]
}
