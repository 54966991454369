// 专门处理权限路由的模块
import { publicRoutes, privateRoutes } from '@/router'

/**
 * 过滤处理权限路由
 * @param {Array} data 数组
 * @param {*} menus 权限菜单数据
 * @returns 返回有权限的路由
 */
const filterPrivateRoutes = (data, menus) => {
  const list = data.filter(item => {
    const index = menus.findIndex(menu => menu.url === item.path)
    if (index === -1) return false
    if (item.children && item.children.length > 0) {
      filterPrivateRoutes(item.children, menus)
    }
    return true
  })
  return list
}

export default {
  namespaced: true,
  state: {
    // 路由表：初始拥有静态路由权限
    routes: publicRoutes
  },
  mutations: {
    /**
     * 增加路由
     */
    setRoutes(state, newRoutes) {
      // 永远在静态路由的基础上增加新路由
      state.routes = [...publicRoutes, ...newRoutes]
    }
  },
  actions: {
    /**
     * 根据权限筛选路由
     */
    filterRoutes(context, menus) {
      const routes = []
      // 路由权限匹配
      const filterPrivateRoutesArray = filterPrivateRoutes(privateRoutes, menus)
      filterPrivateRoutesArray.forEach(route => {
        // 权限名 与 路由的 name 匹配
        routes.push(route)
      })
      // 最后添加 不匹配路由进入 404
      routes.push({
        path: '/:catchAll(.*)',
        redirect: '/404'
      })
      context.commit('setRoutes', routes)
      return routes
    }
  }
}
