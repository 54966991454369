import layout from '@/layout'

export default {
  path: '/sys',
  component: layout,
  redirect: '/sys/banner-manager',
  name: 'sysManage',
  meta: {
    title: '系统管理',
    icon: 'personnel'
  },
  children: [
    {
      path: '/sys/banner-manager',
      component: () => import('@/views/sys/banner-manager/index'),
      meta: {
        title: 'banner配置',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/sys/system-config',
      component: () => import('@/views/sys/system-config/index'),
      meta: {
        title: '系统配置',
        icon: 'personnel-manage'
      }
    }
  ]
}
