import dayjs from 'dayjs'
import rt from 'dayjs/plugin/relativeTime'
// 语言包
import 'dayjs/locale/zh-cn'
import store from '@/store'

const dateFilter = (val, format = 'YYYY-MM-DD') => {
  if (!val) return ''
  if (!isNaN(val)) {
    val = parseInt(val)
  }

  return dayjs(val).format(format)
}

const dateTimeFilter = (val, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!val) return ''
  if (!isNaN(val)) {
    val = parseInt(val)
  }

  return dayjs(val).format(format)
}

// 加载相对时间插件
dayjs.extend(rt)
function relativeTime(val) {
  if (!isNaN(val)) {
    val = parseInt(val)
  }
  return dayjs()
    .locale(store.getters.language === 'zh' ? 'zh-cn' : 'en')
    .to(dayjs(val))
}

/**
 * 处理余额格式
 */
const filterBalance = val => {
  val = parseFloat(val)
  if (val >= 10000) {
    return (val / 10000).toFixed(2) + 'W'
  } else {
    if (val < 1000) {
      return val.toFixed(2)
    } else {
      return (val / 1000).toFixed(2) + 'K'
    }
  }
}

export default app => {
  app.config.globalProperties.$filters = {
    dateFilter,
    dateTimeFilter,
    relativeTime,
    filterBalance
  }
}
