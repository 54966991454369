import request from '@/utils/request'

/**
 * 登录
 */
export const login = data => {
  return request({
    url: '/system/auth/login',
    method: 'POST',
    data
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/system/userInfo/userInfo'
  })
}

/**
 * 获取当前用户的菜单
 */
export const getUserInfoMenu = () => {
  return request({
    url: '/system/userInfo/userInfoMenu'
  })
}

/**
 * 查询banner等配置
 */
export const bannerList = data => {
  return request({
    url: '/manager/config/getBanner',
    params: data
  })
}

/**
 * 创建banner图
 */
export const addBanner = data => {
  return request({
    url: '/manager/config',
    method: 'POST',
    data
  })
}

/**
 * 更新
 */
export const updateBanner = data => {
  return request({
    url: '/manager/config',
    method: 'PUT',
    data
  })
}

/**
 * 删除指定数据
 */
export const deleteBanner = id => {
  return request({
    url: `/manager/config/${id}`,
    method: 'DELETE'
    // data: ids
  })
}

/**
 * 上传图片(返回图片路径和压缩图片路径)
 */
export const uploadImage = data => {
  return request({
    url: '/manager/warehouse/uploadImage',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    data
  })
}

/**
 * 上传视频
 */
export const uploadVideo = data => {
  return request({
    url: '/manager/warehouse/uploadVideo',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    data
  })
}

/**
 * 设置分享
 */
export const updateshare = data => {
  return request({
    url: '/manager/wxUser/updateshare',
    method: 'PUT',
    data
  })
}

/**
 * 查询分享
 */
export const getshare = () => {
  return request({
    url: '/pass/wxUser/getshare'
  })
}

/**
 * 查询分享图配置
 */
export const getshareImg = id => {
  return request({
    url: `/manager/config/getShare/${id}`
  })
}

/**
 * 添加分享图配置
 */
export const addShareImg = data => {
  return request({
    url: '/manager/config/addShare',
    method: 'PUT',
    data
  })
}

/**
 * 修改分享图配置
 */
export const updateShareImg = data => {
  return request({
    url: '/manager/config/updateShare',
    method: 'PUT',
    data
  })
}
