import layout from '@/layout'

export default {
  path: '/store',
  component: layout,
  redirect: '/store/manage',
  name: 'storeManage',
  meta: {
    title: '店铺管理',
    icon: 'personnel'
  },
  children: [
    {
      path: '/store/manage',
      component: () => import('@/views/store-manager/store/index'),
      meta: {
        title: '店铺列表',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/store/class/:storeId/:storeName',
      component: () => import('@/views/store-manager/store-class/index'),
      name: 'storeClass',
      props: true,
      meta: {
        title: '商品分类'
      }
    }
  ]
}
